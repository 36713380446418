import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var CryptoAssetManagementEmailSendDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation CryptoAssetManagementEmailSend(\n    $input: ContactFormAssetManagementEmailSendInput!\n  ) {\n    contactFormAssetManagementEmailSend(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"])));
export var CryptoEmailSendDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation CryptoEmailSend($input: ContactFormEmailSendInput!) {\n    contactFormEmailSend(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"])));
export var SubscribeUserViaEmailDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation SubscribeUserViaEmail(\n    $email: Email\n    $googleRecaptchaToken: String\n    $source: String\n  ) {\n    subscribeUserViaEmail(\n      input: {\n        subscribe: {\n          email: $email\n          googleRecaptchaToken: $googleRecaptchaToken\n          source: $source\n        }\n      }\n    ) {\n      xLead\n      errors {\n        message\n      }\n    }\n  }\n"])));
export var UserSubscriptionsDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query UserSubscriptions($slug: String!) {\n    user(slug: $slug) {\n      id\n      email\n      subscriptions {\n        id\n        body\n        description\n        name\n        preRegister\n        redirectUrl\n        subject\n      }\n    }\n  }\n"])));