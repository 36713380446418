export var viewportPosition = {
  once: true,
  amount: 0.3
};
export var scrollVariants = {
  offscreen: {
    y: 200,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 1.5
    }
  }
};
export var headerScrollVariants = {
  offscreen: {
    y: 100,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 1.5
    }
  }
};
export var inverseHeaderScrollVariants = {
  offscreen: {
    y: -100,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 1.5
    }
  }
}; // Used on section-header buttons

export var buttonScrollVariants = {
  offscreen: {
    y: 100,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 1.5
    }
  }
}; // Used on section-header buttons

export var staggerScrollVariants = {
  hidden: {
    opacity: 0
  },
  show: {
    opacity: 1,
    transition: {
      duration: 1.5,
      staggerChildren: 0.1
    }
  }
};
export var accordionStaggerScrollVariants = {
  hidden: {
    opacity: 0
  },
  show: {
    opacity: 1,
    transition: {
      duration: 2.0,
      staggerChildren: 0.3
    }
  }
};
export var staggerInItem = {
  hidden: {
    opacity: 0
  },
  show: {
    opacity: 1
  }
};
export var teamScrollFadeInVariant = {
  offscreen: {
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 2,
      staggerChildren: 0.5
    }
  }
};
export var teamScrollRightVariant = {
  offscreen: {
    x: 200,
    opacity: 0
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 1,
      staggerChildren: 0.5
    }
  }
};
export var teamScrollLeftVariant = {
  offscreen: {
    x: -200,
    opacity: 0
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 1,
      staggerChildren: 0.5
    }
  }
};