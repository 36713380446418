function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import isValid from 'date-fns/isValid';
import { isString, isStringOrUndefined } from '@r-client/shared/util/core';
export var isPhone = function isPhone(value) {
  var filtered = "".concat(value).replace(/[\s-())]+/g, '');
  return /^\+?[\d]{8,}$/g.test(filtered);
};
export var phoneValidation = function phoneValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please enter a valid phone number';
  return function (val) {
    return typeof val === 'string' && isPhone(val) || !val ? undefined : message;
  };
};
export var isValidEmail = function isValidEmail(email) {
  var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
};
export var emailValidation = function emailValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please enter a valid email';
  return function (val) {
    return typeof val === 'string' && isValidEmail(val) ? undefined : message;
  };
};
export var isValidUrl = function isValidUrl(url) {
  var pattern = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;
  return pattern.test(url);
};
export var urlValidation = function urlValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please enter a valid URL';
  return function (val) {
    return typeof val === 'string' && isValidUrl(val) || !val ? undefined : message;
  };
};
export var isUrlForDomains = function isUrlForDomains(domains, url) {
  var domainsForRegex = domains.map(function (_ref) {
    var baseDomain = _ref.baseDomain,
        flexSubdomainCount = _ref.flexSubdomainCount;
    return "[^.]+\\.".repeat(flexSubdomainCount !== null && flexSubdomainCount !== void 0 ? flexSubdomainCount : 0) + baseDomain.replace('.', '\\.');
  }).join('|');
  var pattern = new RegExp("^(https?://)?(www\\.)?(".concat(domainsForRegex, ")/"));
  return pattern.test(url);
};
export var isValidLinkedinUrl = function isValidLinkedinUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'linkedin.com'
  }], url);
};
export var isValidFacebookUrl = function isValidFacebookUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'facebook.com'
  }], url);
};
export var isValidAngelListUrl = function isValidAngelListUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'angel.co'
  }], url);
};
export var isValidDiscordUrl = function isValidDiscordUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'discord.gg'
  }, {
    baseDomain: 'discord.io'
  }, {
    baseDomain: 'discord.me'
  }, {
    baseDomain: 'discord.li'
  }, {
    baseDomain: 'discord.com'
  }, {
    baseDomain: 'discordapp.com'
  }], url);
};
export var isValidCrunchbaseUrl = function isValidCrunchbaseUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'crunchbase.com'
  }], url);
};
export var isValidInstagramUrl = function isValidInstagramUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'instagram.com'
  }, {
    baseDomain: 'instagr.am'
  }, {
    baseDomain: 'instagr.com'
  }], url);
};
export var isValidYoutubeUrl = function isValidYoutubeUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'youtube.com'
  }], url);
};
export var isValidMediumUrl = function isValidMediumUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'medium.com',
    flexSubdomainCount: 1
  }], url);
};
export var isValidPinterestUrl = function isValidPinterestUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'pinterest.com'
  }], url);
};
export var isValidTikTokUrl = function isValidTikTokUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'tiktok.com'
  }], url);
};
export var isValidTwitterUrl = function isValidTwitterUrl(url) {
  return isValidUrl(url) && isUrlForDomains([{
    baseDomain: 'twitter.com'
  }], url);
};
export var isValidEthereumAddress = function isValidEthereumAddress(address) {
  return /^0x[a-fA-F0-9]{40}$/g.test(address);
};
export var isValidStellarAddress = function isValidStellarAddress(address) {
  return /^[A-Z0-9]{56}$/g.test(address);
};
export var repeated = function repeated(minTimes) {
  var test = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
    return true;
  };
  return function (input) {
    var _input$split$reduce = input.split('').reduce(function (acc, letter) {
      var count = test(letter) && acc.prev === letter ? acc.count + 1 : 1;
      return {
        prev: letter,
        count: count,
        maxCount: Math.max(acc.maxCount, count)
      };
    }, {
      count: 1,
      maxCount: 1
    }),
        maxCount = _input$split$reduce.maxCount;

    return maxCount >= minTimes;
  };
};
export var format = function format(txt) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  return txt.replace(/\$([0-9])/g, function (_, val) {
    return args[+val];
  });
};
export var required = function required() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "Can't be blank";
  return function (val) {
    return val ? undefined : message;
  };
};
export var moreEq = function moreEq(len) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Minimum $0";
  return function (val) {
    return val && "".concat(val).length >= len ? undefined : format(message, len);
  };
};
export var more = function more(len) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Must be more than $0";
  return function (val) {
    return val && "".concat(val).length > len ? undefined : format(message, len);
  };
};
export var lessEq = function lessEq(len) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Maximum $0";
  return function (val) {
    return val && "".concat(val).length <= len ? undefined : format(message, len);
  };
};
export var less = function less(len) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Must be less than $0";
  return function (val) {
    return val && "".concat(val).length < len ? undefined : format(message, len);
  };
};
export var eq = function eq(len) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Must be equal to $0";
  return function (val) {
    return val && "".concat(val).length === len ? undefined : format(message, len);
  };
};
export var regExp = function regExp(rx, message) {
  return function (val) {
    return isString(val) && rx.test(val) ? undefined : message;
  };
};
export var condSkip = function condSkip(test, fn) {
  return function (val) {
    if (test(val)) {
      return fn(val);
    }

    return undefined;
  };
};
export var compose = function compose() {
  for (var _len2 = arguments.length, fns = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    fns[_key2] = arguments[_key2];
  }

  return function (val) {
    var _iterator = _createForOfIteratorHelper(fns),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var fn = _step.value;
        var res = fn === null || fn === void 0 ? void 0 : fn(val);

        if (res) {
          return res;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return;
  };
};
export function isAbbr(value) {
  return !!value && !value.includes('/') && !value.includes('.') && !value.includes('llc') && !value.includes('&') && !value.includes('trust') && !value.includes('fund') && value.length > 0;
}
export function isShortName(value) {
  var words = (value || '').split(' ').map(function (w) {
    return w.trim();
  }).filter(Boolean);

  if (words.length === 1 && /^\d+$/.test(words[0])) {
    return false;
  }

  return words.length >= 1 && words.map(function (word) {
    return word.length;
  }).reduce(function (max, length) {
    return Math.max(max, length);
  }, 0) >= 3;
} // latin and numbers

export function isOnlyLatin(value) {
  var _value$replace$split$;

  var exceptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return (_value$replace$split$ = value === null || value === void 0 ? void 0 : value.replace(/\s/g, '').split('').every(function (letter) {
    var exceptionsResult = Array.prototype.join.call(exceptions, ''); // See https://linear.app/republic/issue/REA-1158/validation-allow-to-use-symbols-in-the-name-field

    if ("-\u2014'".concat(exceptionsResult).indexOf(letter) >= 0) {
      return true;
    }

    if (/\W/.test(letter[0])) {
      return false;
    }

    return true;
  })) !== null && _value$replace$split$ !== void 0 ? _value$replace$split$ : true;
}
export var isLatinChars = function isLatinChars(val) {
  return isString(val) ? /^[a-z ,.'-]*$/i.test(val) : false;
};
export var isLatinCharsValidation = function isLatinCharsValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please use latin characters';
  return function (val) {
    return isStringOrUndefined(val) && isLatinChars(val) ? undefined : message;
  };
};
export var isOnlyLatinValidation = function isOnlyLatinValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please use latin characters';
  var exceptions = arguments.length > 1 ? arguments[1] : undefined;
  return function (val) {
    return isStringOrUndefined(val) && isOnlyLatin(val, exceptions) ? undefined : message;
  };
};
export var abbrValidation = function abbrValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please enter your full legal name without abbreviation';
  return function (val) {
    return isString(val) && isAbbr(val) ? undefined : message;
  };
};
export var shortNameValidation = function shortNameValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please enter your full legal name without abbreviation';
  return function (val) {
    return isStringOrUndefined(val) && isShortName(val) ? undefined : message;
  };
};
export var notDigits = function notDigits() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "Please don't use only digits";
  return function (val) {
    return isString(val) && /^[0-9 ]+$/.test(val) ? message : undefined;
  };
};
export var fullLegalNameValidation = function fullLegalNameValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please enter your full legal name without abbreviation';
  return compose(abbrValidation(message), shortNameValidation(message));
};
export var fullNameValidationWithoutShortName = function fullNameValidationWithoutShortName(p) {
  return compose(required(p === null || p === void 0 ? void 0 : p.required), shortNameValidation(p === null || p === void 0 ? void 0 : p.fullLegalNameValidation), isOnlyLatinValidation(p === null || p === void 0 ? void 0 : p.isOnlyLatinValidation, p === null || p === void 0 ? void 0 : p.exceptions));
};
export var fullNameValidation = compose(required(), fullLegalNameValidation(), isOnlyLatinValidation());
export function isLatinAddress(value) {
  return (value !== null && value !== void 0 ? value : '').replace(/\s/g, '').split('').every(function (letter) {
    return /[a-zA-Z0-9,.:;/'`‘’‚‘"″«»“”„“‟”\-_=一]/.test(letter[0]);
  });
}
export var addressValidation = function addressValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please use latin characters';
  return function (val) {
    return isStringOrUndefined(val) && isLatinAddress(val) ? undefined : message;
  };
};
export var addressRequiredValidation = compose(required(), addressValidation(), notDigits('Must be a real address')); // Ref: https://date-fns.org/v2.21.3/docs/isValid

export var isValidDate = function isValidDate() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Invalid date';
  return function (val) {
    return (typeof val === 'number' || typeof val === 'string') && isValid(new Date(val)) ? undefined : message;
  };
};
export var requiredAndPositiveAmount = function requiredAndPositiveAmount() {
  return compose(required(undefined), more(0, "Can't be $$0"));
};
export var validationTrigger = function validationTrigger(test, fn) {
  return function (val) {
    if (test(val)) {
      return fn(val);
    }

    return undefined;
  };
};
export var isValidPassword = function isValidPassword(password) {
  return /^.{8,72}$/.test(password);
};
export var passwordValidation = function passwordValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Too short (minimum is 8 characters)';
  return function (val) {
    return typeof val === 'string' && isValidPassword(val) ? undefined : message;
  };
};
export var confirmPassword = function confirmPassword(password) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Passwords must match';
  return function (val) {
    return val === password ? undefined : message;
  };
};
export var minimumLength = function minimumLength(length) {
  return function (val) {
    return (typeof val === 'string' && (val === null || val === void 0 ? void 0 : val.length) || 0) < length ? "".concat(length, " character minimum") : undefined;
  };
};
export var maximumLength = function maximumLength(length) {
  return function (val) {
    return (typeof val === 'string' && (val === null || val === void 0 ? void 0 : val.length) || Infinity) > length ? "".concat(length, " character maximum") : undefined;
  };
};
export var exactLength = function exactLength(length) {
  return function (val) {
    return (typeof val === 'string' && (val === null || val === void 0 ? void 0 : val.length) || -1) !== length ? "Must be ".concat(length, " characters long") : undefined;
  };
};
export var containsLetter = function containsLetter() {
  return function (val) {
    return typeof val === 'string' && !val.match(/[a-z]/i) ? "Must contain one letter" : undefined;
  };
};
export var containsDigit = function containsDigit() {
  return function (val) {
    return typeof val === 'string' && !val.match(/[0-9]/) ? "Must contain one number" : undefined;
  };
};
export var isInteger = function isInteger() {
  return function (val) {
    return typeof val === 'string' && !val.match(/^[0-9]*$/) ? "Must be only digits (0-9)" : undefined;
  };
};
export var containsAtLeastOneOf = function containsAtLeastOneOf(charList) {
  return function (val) {
    return typeof val === 'string' && !val.match(new RegExp("[".concat(charList.join(''), "]"))) ? "Must contain at least one of (".concat(charList, ")") : undefined;
  };
};